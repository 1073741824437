/**
 * UCSC Xena Client
 * http://xena.ucsc.edu
 *
 * Xena footer, writes itself into the element with ID "footer" in page.template.
 */


// Core dependencies, components
var React = require('react');
var ReactDOM = require('react-dom');

// Styles
var compStyles = require('./footer.module.css');

class Footer extends React.Component {
	render() {
		return (
			<div className={compStyles.footer}>
				<div className={compStyles.footerLinks}>
					<ul>
						<li><a href='https://www.georgetown.edu/' target='_blank'>Georgetown University</a></li>
						<li><a href='https://lombardi.georgetown.edu/' target='_blank'>Georgetown Lombardi Comprehensive Cancer Center</a></li>
						<li><a href='https://gumc.georgetown.edu/' target='_blank'>Georgetown University Medical Center</a></li>
						<li><a href='https://icbi.georgetown.edu/' target='_blank'>ICBI</a></li>
						
					</ul>
					<ul>
						<li><a href='mailto:icbi@georgetown.edu'>Email</a></li>
						<li><a href='https://twitter.com/icbi_georgetown' target='_blank'>Twitter</a></li>
						<li><a href='https://github.com/ICBI' target='_blank'>Github</a></li>
					</ul>
					
				</div>
				<div className={compStyles.footerLinks}>
				<ul>
				<li>
						Copyright © 2020, Innovation Center for Biomedical Informatics. All Rights Reserved. G-DOC ® 4.0
						</li>
					<li>
						Powered by <a href='http://xena.ucsc.edu/' target='_blank'>UCSC Xena</a>
					</li>	
				</ul>
					
				</div>
			</div>
		);
	}
}

var footer = document.getElementById('footer');

ReactDOM.render(<Footer />, footer);
