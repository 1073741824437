import React from 'react';
import PureComponent from './PureComponent';
import { Grid, Row, Col } from "react-material-responsive-grid";
import { AppControls } from './AppControls';
import { KmPlot } from './KmPlot';
import SheetControls from './SheetControls';
import { StateError } from './StateError';
var _ = require('./underscore_ext').default;
import { Stepper } from './views/Stepper';
import Welcome from './containers/WelcomeContainer';
import '../css/index.css'; // Root styles file (reset, fonts, globals)
import { ThemeProvider } from 'react-css-themr';
import appTheme from './appTheme';
import nav from './nav';
var gaEvents = require('./gaEvents');
//var Perf = require('react-dom/lib/ReactPerf');


// Styles
import compStyles from'./views/Welcome.module.css';
import typStyles from '../css/typography.module.css';

// Images
import welcomeImg from '../images/home.png';
import welcome2xImg from '../images/homex2.png';
import welcome3xImg  from '../images/homex3.png';
import gdocNImg  from '../images/gdocN.png';

let welcomeSrcSet = `${welcome2xImg} 2x, ${welcome3xImg} 3x`;


const stepperSteps = [
	{ label: 'Select a Study to Explore' },
	{ label: 'Select Your First Variable' },
	{ label: 'Select Your Second Variable' }
];
const stepperStateIndex = {
	'COHORT': 0,
	'FIRST_COLUMN': 1,
	'SECOND_COLUMN': 2
};

// should really be in a config file.
const searchHelp = 'https://ucsc-xena.gitbook.io/project/overview-of-features/filter-and-subgrouping';


function clearZoom(samples, zoom) {
	return _.merge(zoom, { count: samples, index: 0 });
}

function zoomOut(samples, zoom) {
	var { count, index } = zoom;
	var nCount = Math.min(samples, Math.round(count * 3)),
		maxIndex = samples - nCount,
		nIndex = Math.max(0, Math.min(Math.round(index + (count - nCount) / 2), maxIndex));

	return _.merge(zoom, { count: nCount, index: nIndex });
}

class Application extends PureComponent {
	//	onPerf = () => {
	//		if (this.perf) {
	//			this.perf = false;
	//			console.log('stop perf');
	//			Perf.stop();
	//			var m = Perf.getLastMeasurements();
	//			console.log('inclusive');
	//			Perf.printInclusive(m);
	//			console.log('exclusive');
	//			Perf.printExclusive(m);
	//			console.log('wasted');
	//			Perf.printWasted(m);
	//		} else {
	//			this.perf = true;
	//			console.log('start perf');
	//			Perf.start();
	//		}
	//	}
	componentDidUpdate() {
	//	const { getState, onImport, onNavigate, state: { isPublic } } = this.props;

	const { getState, onImport, state: { isPublic } } = this.props,
		{onNavigate} = this;
		// nested render to different DOM tree
		nav({ isPublic, getState, onImport, onNavigate, activeLink: 'home' });
	
	}

	onClearZoom = () => {
		const { state: { samples, zoom } } = this.props;
		this.props.callback(['zoom', clearZoom(samples.length, zoom)]);
	};
	onHideError = () => {
		this.props.callback(['stateError', undefined]);
	};
	onShowWelcome = () => {
		this.props.onShowWelcome(true);
	};
	onHideWelcome = () => {
		this.props.onShowWelcome(false);
	};
	onZoomOut = () => {
		const { state: { samples, zoom } } = this.props;
		gaEvents('spreadsheet', 'zoom', 'out');
		this.props.callback(['zoom', zoomOut(samples.length, zoom)]);
		this.props.callback(['enableTransition', false]);
	};
	
	onNavigate = (page, params) => {
		this.props.callback(['navigate', page, params]);
	};
	//	onSearchIDAndFilterColumn = (qsamplesList) => {
	//		var {state: {samples, cohortSamples}} = this.props,
	//			qsampleListObj = {},
	//			cohortSamplesList = [];
	//
	//		_.map(qsamplesList, (s, i)=>{
	//			qsampleListObj[s] = i + 1;
	//		});
	//		cohortSamplesList = _.flatten(_.map(Object.keys(cohortSamples), i => cohortSamples[i]));
	//
	//		var matches = _.filter(samples, s => qsampleListObj[cohortSamplesList[s]]),
	//			fieldLabel = matches.length + ((matches.length === 1) ? ' match' : ' matches');
	//		this.onFilterColumn(matches, 'sample list', fieldLabel);
	//	};
	render() {
		let { state, stateError, children, stepperState, loadPending, ...otherProps } = this.props,
			{ callback } = otherProps,
			{ editing, wizardMode, showWelcome, zoom } = state;
		//			onSearchIDAndFilterColumn = this.onSearchIDAndFilterColumn;

		if (loadPending) {
			return <p style={{ margin: 10 }}>Loading your view...</p>;
		}

		return (

			<div>

			<div className={compStyles.Welcome}>
				
				<div className={compStyles.welcomeIcon}>
					<img className={compStyles.imgXena} src={welcomeImg} srcSet={welcomeSrcSet} alt=""/>
				</div>

				<div className={compStyles.welcomeText}>
					<h1 className={typStyles.mdHeadline}>Welcome to G-DOC Hub</h1>
					<h2 className={typStyles.mdSubhead}>
					The Georgetown Database of Cancer (G-DOC) is a precision medicine platform providing access to  public datasets of molecular and clinical data from thousands of patients and cell lines, along with tools for analysis and data visualization. The platform enables the integrative analysis of multiple data  types to understand disease mechanisms.
					</h2>		
				</div>			
			</div>

				<div style={{ position: 'relative' }}> {/* Necessary for containing KmPlot pop-up */}
					{showWelcome ? <Welcome onClick={this.onHideWelcome} /> :
						null}
					<Grid>
					
					<Row>&nbsp;</Row>

						<Row>
							<Col mdOffset={2} md={8}>
							The G-DOC platform is now utilizing  a G-DOC hub powered by UCSC Xena Hubs . G-DOC Hub is  providing access to public and private, multi-omic and clinical/phenotype data, and is supported by the Xena Hub in the backend. Data from the Xena Hub is integrated into a single coherent visualization within the Xena Cancer Genome Browser . The new G-DOC platform powered by UCSC Xena Hub continues to allow the integrative analysis of multiple data types to understand disease mechanisms; to discover new trends and validate findings.
							</Col>
						</Row>
						<Row>&nbsp;</Row>
			
						<Row>
							<Col mdOffset={2} md={8}>
							<h3>Citation</h3>
							</Col>
						</Row>

						<Row>
						<Col mdOffset={2} md={8}>
 						<ul>
							<li>Madhavan S, Gusev Y, Harris M, Tanenbaum DM, Gauba R, Bhuvaneshwar K, Shinohara A, Rosso K, Carabet L, Song L, Riggins RB, Dakshanamurthy S, Wang Y, Byers SW, Clarke R, Weiner LM.  G-DOC®: A Systems Medicine Platform for Personalized Oncology Neoplasia 13:9. (Sep 2011). PMID: 21969811
</li>
							<li>Krithika Bhuvaneshwar, Anas Belouali, Varun Singh, Robert M Johnson, Lei Song, Adil Alaoui, Michael A Harris, Robert Clarke, Louis M Weiner, Yuriy Gusev, Subha Madhavan. G-DOC Plus – an integrative bioinformatics platform for precision medicine. BMC Bioinformatics (April2016). PMID: 27130330
</li>
							<li>Goldman, M.J., Craft, B., Hastie, M. et al. Visualizing and interpreting cancer genomics data via the Xena platform. Nat Biotechnol 38, 675–678 (2020).
<a href="https://www.nature.com/articles/s41587-020-0546-8" target="_blank">https://doi.org/10.1038/s41587-020-0546-8 </a>  </li>
						</ul> 


						</Col>
						</Row>
						<Row>&nbsp;</Row>

					</Grid>
					{_.getIn(state, ['km', 'id']) ? <KmPlot
						callback={callback}
						survivalKeys={_.keys(state.survival)}
						km={state.km}
						cohort={state.cohort.name} /> : null}
					{stateError ? <StateError onHide={this.onHideError} error={stateError} /> : null}
				</div>
			</div>
		);
	}
}

const ThemedApplication = (props) => {
	return (
		<ThemeProvider theme={appTheme}>
			<Application {...props} />
		</ThemeProvider>);
};

module.exports = ThemedApplication;
