/**
 * UCSC Xena Client
 * http://xena.ucsc.edu
 *
 * Welcome bar component, displayed when user visits Xena. Dismissed by close icon in top right corner.
 *
 * Visibility of welcome bar is controlled by parent component.
 *
 * Single action, onClick, is invoked to update the visibility state of the welcome bar.
 */


// Core dependencies, components
import React, { Component } from 'react';
var {times} = require('./../underscore_ext').default;

// Styles
import compStyles from'./Welcome.module.css';
import typStyles from '../../css/typography.module.css';

// Images
import welcomeImg from '../../images/home.png';
import welcome2xImg from '../../images/homex2.png';
import welcome3xImg  from '../../images/homex3.png';

let welcomeSrcSet = `${welcome2xImg} 2x, ${welcome3xImg} 3x`;

class Welcome extends Component {
	dismissWelcome = () => {
		this.props.onClick();
	};
	render() {
		const {link: [app, bookmark, text], count, i, linkProps, bulletProps} = this.props,
			link = `${document.location.origin}/${app}/?bookmark=${bookmark}`;
		return(
			<div className={compStyles.Welcome}>
				<div className={compStyles.welcomeIcon}>
					<img className={compStyles.imgXena} src={welcomeImg} srcSet={welcomeSrcSet}/>
				</div>
				<div className={compStyles.welcomeText}>
					<h1 className={typStyles.mdHeadline}>Welcome to G-DOC</h1>
					<h2 className={typStyles.mdSubhead}>The Georgetown Database of Cancer (G-DOC) is a precision medicine platform containing molecular and clinical data from thousands of patients and cell lines, along with tools for analysis and data visualization. The platform enables the integrative analysis of multiple data types to understand disease mechanisms. G-DOC has three overlapping entry points for the user based on their interests: 1) Personalized Medicine, 2) Translational Research, and 3) Population Genetics. Please leave us feedback on your experience!</h2>
					
				{/* <div className={compStyles.bulletWrapper}>
						<div className={compStyles.bullets}>
							{times(count, j => <div
													key={j}
													data-index={j}
													{...bulletProps}
													className={i === j ? compStyles.bulletActive : compStyles.bullet}>

													{'\u2022'}
												</div>)}
						</div>
					</div> */}
					
				</div>
				<div className={compStyles.closeIcon} onClick={this.dismissWelcome}>
				{/* 	<i className='material-icons'>close</i>  */}
				</div>
			</div>
		);
	}

}

module.exports = Welcome;
